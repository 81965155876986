<template>
  <div>
    <div class="header" style="margin-bottom:20px;">
      <h4>管理系统页面</h4>
    </div>

    <el-row :gutter="24">
      <el-col :span="8" v-access data-ctrl="agent.enterCheckCard">
        <el-card
          shadow="always"
          v-prev
          path="/system/agent/agentReconciliation"
        >
          <el-button size="medium" type="text">代理端对账</el-button>
        </el-card>
      </el-col>
      <el-col :span="8" v-access data-ctrl="agent.enterCustomerCard">
        <el-card
          shadow="always"
          v-prev
          path="/system/agent/contractingEnterprisesList"
        >
          <el-button size="medium" type="text"> 企业客户管理</el-button>
        </el-card>
      </el-col>
      <el-col :span="8" v-access data-ctrl="agent.enterSalesCard">
        <el-card
          shadow="always"
          v-prev
          path="/system/agent/businessPersonnelList"
        >
          <el-button size="medium" type="text"> 业务人员</el-button>
        </el-card>
      </el-col>
      <el-col :span="8" v-access data-ctrl="followEnterprise.enterCard">
        <el-card shadow="always" v-prev path="/system/followEnterprise/list">
          <el-button size="medium" type="text"> 跟进的企业</el-button>
        </el-card>
      </el-col>
    </el-row>
    <!-- <div class="header" style="margin-bottom:20px;">
      <h4>代理统计</h4>
    </div>
    <el-card
      class="box-card"
      body-style="display: flex;
    align-items: center;
    justify-content: space-around;"
    >
      <span>今日新增交易额：8071元</span>
      <span>累计交易额：17258071元</span>
      <span>新增企业：1</span>
      <span>合作企业总数：32</span>
    </el-card> -->
    <!-- <el-row
      :gutter="24"
      class="content"
      style="margin-top: 32px"
      justify="center"
    > -->
    <!-- <el-col :span="20" > -->
    <!-- <UserEchart1></UserEchart1> -->
    <!-- </el-col> -->

    <!-- <div class="right-border">
          <div class="right-top">
            <el-badge :value="12" class="item">
              <el-button size="small">我的代办</el-button>
            </el-badge>
          </div>
        </div> -->
    <!-- <div class="dbsx-border">
          <div class="dbsx-top">
            <span>发薪排行</span>
            <el-date-picker
              v-model="value"
              type="month"
              placeholder="选择日期时间"
              prefix-icon="el-icon-date"
              format="yyyy-MM"
              size="mini"
              style="width:150px;border-radius: 10px;"
              @change="getrq(value)"
            >
            </el-date-picker>
          </div>

          <div class="dbsx-one">
            <span>1.20号按时发放工资</span>
          </div>
        </div> -->

    <!-- </el-row> -->
  </div>
</template>

<script>
// 接口：
// import { api } from "../../api/base/index";
// 新增接口

// // 查询列表接口
// export const ContentList = api("/content")("content.list.json");
// // 查询详情接口
// export const ContentDetail = api("/content")("content.describe.json");
// // 编辑接口
// export const ContentEdit = api("/content")("content.edit.json");
// // 删除接口
// export const ContentDel = api("/content")("content.del.json");
// export const ContentTypeList = api("/content")("content.type.list.json");

export default {
  data() {
    return {
      ydate: ' 2021-04',
      value: '',

      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近半年',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一年',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
        // disabledDate(time) {
        //   let _now = Date.now(),
        //     seven = 7 * 24 * 60 * 60 * 1000,
        //     sevenDays = _now - seven;
        //   return time.getTime() > _now || time.getTime() < sevenDays; //大于当前的禁止，小于7天前的禁止
        // }
      },
      value2: ''
    };
  },
  components: {
    // UserEchart1: () => import("./agentManagement-echart.vue")
  },
  mounted() {},
  methods: {
    getrq(date) {
      date = date.getFullYear() + '-' + (date.getMonth() + 1);
      console.log(date);
    },

    xzFwrq(date) {
      // d=d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
      var date1 =
        date[0].getFullYear() +
        '-' +
        (date[0].getMonth() + 1) +
        '-' +
        date[0].getDate();
      var date2 =
        date[1].getFullYear() +
        '-' +
        (date[1].getMonth() + 1) +
        '-' +
        date[1].getDate();
      console.log(date1, date2);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-col {
  margin-bottom: 10px;
}
.clickColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.demo-form-inline {
  display: flex;
  flex-wrap: wrap;
}
.dlList-top {
  margin: 20px 0;
  padding: 40px 40px;

  display: flex;
  align-items: center;
  justify-content: space-around;
  span {
    font-weight: 700;
    font-size: 18px;
    color: #000;
  }
}
.dlList-center {
  display: flex;
  align-items: center;
  .left {
    .left_top {
      font-size: 24px;
      font-weight: 700;
      padding: 20px;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      color: #000;
      background: #eef0f3;
    }
    .left-date {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .content {
      width: 100%;
    }
  }
}
.right-border {
  width: 100%;
  .right-top {
    width: 108px;
    height: 80px;
    text-align: center;
    padding: 10px;
    margin: 20px auto;
    position: relative;
    .daiban {
      border-radius: 10px;
      border: 1px solid #999;
      padding: 20px;
    }
    .tixing {
      background-color: #f56c6c;
      border-radius: 10px;
      color: #fff;
      display: inline-block;
      font-size: 12px;
      height: 18px;
      line-height: 18px;
      padding: 0 6px;
      text-align: center;
      white-space: nowrap;
      border: 1px solid #fff;
      position: absolute;
      top: 0;
      right: 20px;
      transform: translateY(-50%) translateX(100%);
    }
  }
}
.dbsx-border {
  widows: 60%;
  margin: 0 20%;
  border-radius: 10px;
  border: 1px solid #999;
  padding: 0 0 20px 0;
  .dbsx-top {
    padding: 20px;
    border-bottom: 1px solid #999;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dbsx-one {
    display: flex;
    border-bottom: 1px solid #999;
    align-items: center;
    // font-size: 14px;
    padding: 10px 20px;
    .bh {
      border: 1px solid #999;
      border-radius: 50%;
      padding: 1px;
      font-size: 12px;
      margin-right: 5px;
      text-align: center;
    }
  }
}
</style>
